import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useField, useFormikContext } from 'formik';

import Checkbox from '../../Input/Checkbox/Checkbox';

/**
 * If you want to use multiple checkboxes to populate an array, give them the
 * same name, and pass a value. If you just want a single checkbox thats either
 * true or false, don't pass a value
 * @param {value} string the value of a checkbox in a multi-checkbox setup
 * @param {func} onClick an optional function to be called when the checkbox is clicked.
 * Can be helpful for performing actions independent of the value actually changing
 */
const CheckboxFormik = props => {
    const { name, value, submitOnChange, onChangeSideEffect, onClick } = props;
    const [field, helpers] = useField({ name, value, type: 'checkbox' });
    const { submitForm } = useFormikContext();

    /**
     * To be sure the value has been added to formik before
     * we make any other changes, we wait until the checkbox
     * rerenders before we run onChangeSideEffect
     * This might not play well with submitOnChange.
     */
    useEffect(() => {
        if (typeof onChangeSideEffect === 'function') {
            onChangeSideEffect(field.value, field.checked);
        }
    }, [field.value, field.checked]);

    return (
        <Checkbox
            {...props}
            value={field.value}
            checked={field.checked}
            onChange={e => {
                field.onChange(e);
                if (submitOnChange) {
                    submitForm();
                }
            }}
            onClick={onClick}
            onBlur={() => helpers.setTouched(true)}
        />
    );
};

CheckboxFormik.propTypes = {
    submitOnChange: PropTypes.bool,
    onChangeSideEffect: PropTypes.func,
    onClick: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
};

CheckboxFormik.defaultProps = {
    submitOnChange: false,
    onChangeSideEffect: null,
    onClick: null,
};

export default CheckboxFormik;
