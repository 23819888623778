/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import generalUtils from '../../../../Utils/generalUtils';

const Radio = ({
    name,
    id,
    inputIdPrefix,
    checked,
    label,
    onChange,
    autoFocus,
    value,
    labelClassName,
    inputClassName,
    ariaLabelledBy,
    disabled,
}) => {
    const inputId = inputIdPrefix
        ? generalUtils.createValidID(inputIdPrefix, id)
        : id;
    return (
        <label
            htmlFor={inputId}
            className={classNames(
                'input-radio__wrapper',
                { disabled: disabled },
                labelClassName
            )}
        >
            <input
                type="radio"
                id={inputId}
                className={classNames('input-radio--hidden', inputClassName)}
                name={name}
                onChange={onChange}
                checked={checked}
                value={value}
                disabled={disabled}
                autoFocus={autoFocus}
                aria-labelledby={ariaLabelledBy}
            />
            <span className="input-radio__box">
                <div className="input-radio__fill" />
            </span>
            <span className="input-radio__label">{label}</span>
        </label>
    );
};

Radio.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
    inputIdPrefix: PropTypes.string,
    disabled: PropTypes.bool,
};

Radio.defaultProps = {
    label: '',
    autoFocus: false,
    value: '',
    labelClassName: '',
    inputClassName: '',
    ariaLabelledBy: '',
    inputIdPrefix: null,
    disabled: null,
};

export default Radio;
